<template>
  <div class="registration-form">
    <div class="connector" v-if="show_connector">
      <div class="alert alert-info">
        Введите номер телефона зарегистрировавшегося участника, чтобы мы разместили вас за одним столом
      </div>
      <div class="mb-3">
        <label>Номер телефона</label>
        <div class="phone-wrapper">
          <input v-maska data-maska="(###) ###-##-##" class="form-control client-phone" v-model="connector_phone">
          <span class="country-code">+7</span>
        </div>
        <div class="text-center c-red" v-if="connection_error.length > 0">{{connection_error}}</div>
      </div>
      <div class="text-center mb-3">
        <button class="btn btn-loto m-r-10" v-if="connector_phone.length === 15" @click="connect">присоединиться</button>
        <button class="btn btn-dark" @click="toggleConnector">отмена</button>
      </div>
    </div>

    <div v-else>
      <div v-if="event.players_left > 0">
        <div class="mb-3">
          <label>Ваше имя</label>
          <input type="text" class="form-control" v-model="name">
        </div>
        <div class="mb-3">
          <label>Ваша фамилия</label>
          <input type="text" class="form-control" v-model="surname">
        </div>
        <div class="mb-3">
          <label>Ваш номер телефона</label>
          <div class="phone-wrapper">
            <input v-maska data-maska="(###) ###-##-##" class="form-control client-phone" v-model="phone">
            <span class="country-code">+7</span>
          </div>

        </div>

        <div class="text-center mb-5">
          <a class="btn btn-sm btn-success" href="javascript:void(0)" @click="toggleConnector" v-if="connection_token === null">присоединиться к друзьям</a>
          <div v-if="connection_token !== null" class="alert alert-success">
            Вы присоединились к компании с номером <br> {{connector_phone}} <br>
            <span class="cur-pointer c-red" @click="cancelConnection">отмена</span>
          </div>
        </div>
        <div class="mb-5">
          <div class="text-center">
            <h4>Стоимость</h4>
          </div>
          <div class="price-list">
            <div class="price-title">Взрослый билет</div>
            <div class="price">{{new Intl.NumberFormat('ru-RU').format(event.price)}} ₽ </div>
          </div>

          <div class="price-list">
            <div class="price-title">Детский билет</div>
            <div class="price">{{new Intl.NumberFormat('ru-RU').format(event.children_price)}} ₽ </div>
          </div>
          <div class="text-center">
            СОБИРАЙ ДРУЗЕЙ И ПОЛУЧАЙ СКИДКУ
            <br>
            Компания свыше {{event.discount_players_count}} человек - {{new Intl.NumberFormat('ru-RU').format(event.discount_price)}} рублей
          </div>
        </div>

        <div class="text-center">
          <h4>Регистрация</h4>
        </div>
        <div class="players-counter" v-if="event !== null">
          <span class="m-r-10 counter-title">Взрослых участников</span>
          <div class="counter-controls">
          <span class="c-red cur-pointer">
              <svg @click="decCount()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
              </svg>
            </span>
            <span class="players-count">
              {{players_count}}
            </span>
            <span class="c-red cur-pointer">
            <svg @click="incCount()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg>
          </span>
          </div>
        </div>

        <div class="players-counter" v-if="event !== null && event.children_price > 0">
          <span class="m-r-10 counter-title">Дети до 12 лет</span>
          <div class="counter-controls">
          <span class="c-red cur-pointer">
              <svg @click="decChildrenCount()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>
              </svg>
            </span>
            <span class="players-count">
              {{children_count}}
            </span>
            <span class="c-red cur-pointer">
            <svg @click="incChildrenCount()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg>
          </span>
          </div>
        </div>

        <div class="payment" v-if="event !== null">
          <span>Итого: {{new Intl.NumberFormat('ru-RU').format(paymentSum)}} ₽ </span>
          <div class="alert alert-success text-center">
            <img :src="'/img/sbp.png'" style="height:80px;">
            <p class="f-s-18">ОПЛАТА ТОЛЬКО ПО СПБ</p>
          </div>

          <div class="errors mb-3 text-center" v-if="errors.length > 0">
            <p class="c-red mb-0" v-for="error in errors">{{error}}</p>
          </div>
          <span v-if="saving">подождите ...</span>
          <button v-if="!saving" class="btn btn-loto" @click="pay">оплатить участие</button>
          <a style="margin-top: 10px;" href="/" v-if="!saving" class="btn btn-dark">отмена</a>
        </div>
      </div>
      <div v-else>
        <div class="text-center">
          <h4>Регистрация окончена</h4>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import { vMaska } from "maska"
  export default {
    directives: { maska: vMaska },
    data: () => {
      return {
        headers: '',
        event: null,
        players_count:1,
        children_count:0,
        name:'',
        surname:'',
        phone:'',
        saving: false,
        errors:[],
        show_connector: false,
        connector_phone: '',
        connection: false,
        connection_token: null,
        connection_error: '',
        invite: null
      }
    },
    created() {
      this.event = window.gon.event
      this.invite = window.gon.invite
    },
    mounted() {
      this.headers = {'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),'X-Requested-With' : 'XMLHttpRequest','Content-Type': 'application/json'};
    },
    methods: {
      toggleConnector() {
        this.show_connector = !this.show_connector
      },
      incCount() {
        if (this.event !== null && this.players_count <= this.event.players_left) {
          this.players_count += 1
        }
      },
      decCount() {
        if (this.players_count > 1 || (this.connection_token !== null && this.players_count === 1)) {
          this.players_count -= 1
        }
      },
      incChildrenCount() {
        if (this.event !== null && (this.children_count + this.players_count) < this.event.players_limit) {
          this.children_count += 1
        }
      },
      decChildrenCount() {
        if (this.children_count > 0) {
          this.children_count -= 1
        }
      },
      pay() {
        this.errors = []
        if (this.name.trim().length === 0) {
          this.errors.push('укажите ваше имя')
        }
        if (this.surname.trim().length === 0) {
          this.errors.push('укажите вашу фамилию')
        }
        if (this.phone.trim().length === 0) {
          this.errors.push('укажите ваш номер телефона')
        }
        if (this.phone.trim().length > 0 && this.phone[1] !== '9') {
          this.errors.push('номер телефона введен некорректно')
        }
        if (this.players_count < 1 && this.children_count < 1) {
          this.errors.push('не указано количество участников')
        }

        if (this.errors.length > 0) {
          return
        }

        const _this = this
        this.saving = true;
        fetch('/events/register', {
          method: 'POST',
          headers:_this.headers,
          body: JSON.stringify({
                name:_this.name,
                surname:_this.surname,
                phone: _this.phone,
                qty: _this.players_count,
                children_qty: _this.children_count,
                event_token: _this.event.token,
                connection_token: _this.connection_token,
                invite: _this.invite
              }
          )
        })
            .then(response => response.json())
            .then(function(data) {
              if (data.token !== undefined) {
                _this.setupTokens(data.token)
              }
              location.href = data.url
            })
            .catch((error) => {
              _this.errors.push(error)
              console.error('Error:', error);
            });
      },
      connect() {
        const _this = this;
        this.connecting = true;
        fetch('/events/connect', {
          method: 'POST',
          headers:_this.headers,
          body: JSON.stringify({
                connector_phone:_this.connector_phone,
                event_token: _this.event.token
              }
          )
        })
            .then(response => response.json())
            .then(function(data) {
              // console.log(data)
              if (data.token === null) {
                _this.connection_error = 'номер не найден'
              } else {
                _this.connection_error = ''
                _this.connection_token = data.token
                _this.toggleConnector()
              }
            })
            .catch((error) => {
              _this.errors.push(error)
              console.error('Error:', error);
            });
      },
      cancelConnection() {
        if (confirm('вы уверены?')) {
          this.connection_token = null
          this.connector_phone = ''
        }
      },
      setupTokens(token) {
        let currentTokens = localStorage.getItem('tokens');
        if (currentTokens === null) {
          localStorage.setItem('tokens',JSON.stringify([token]));
        } else {
          let tokens = JSON.parse(currentTokens)
          tokens.push(token)
          localStorage.setItem('tokens',JSON.stringify(tokens));
        }
      }
    },
    computed: {
      paymentSum() {
        let children_sum = this.children_count*this.event.children_price
        if (this.event.discount_players_count > 0 && this.event.discount_price > 0 && this.players_count >=this.event.discount_players_count) {
          return this.players_count*this.event.discount_price + children_sum
        } else {
          return this.players_count*this.event.price + children_sum
        }
      }
    }
  }
</script>

<style>
  .players-counter {
    user-select: none;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
  }

  .players-count {
    display: flex;
    justify-content: center;
    min-width: 30px;
    padding: 0 5px;
    font-size: 20px;
  }

  .payment {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .payment span {
    font-size: 20px;
    display: block;
    margin-bottom: 10px;
  }

  .counter-title {
    display: block;
    width: 240px;

  }

  .counter-controls {
    width:  100%;
    display: flex;
    justify-content: flex-end;
  }

  .price-list {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  .client-phone {
    padding-left: 25px;
  }

  .phone-wrapper {
    position: relative;
  }

  .phone-wrapper .country-code {
    position: absolute;
    top: 7px;
    left: 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }

</style>